<template>
  <q-page class=" ">
    <q-dialog v-model="mostrar_link_mp">
      <qr-wpp :telefono="telefono" :link="link"></qr-wpp>
    </q-dialog>
    <q-dialog v-model="dialog_info_comprador">
      <q-card>
        <q-card-section class="q-pt-none q-pb-none">
          <div>
            <q-card-section>
              <div class="text-h6">Información de la venta</div>
            </q-card-section>
            <q-card-section class="q-pt-none q-pb-none">
              <p v-for="datos in Object.keys(datos_venta)" :key="datos"><b>{{ datos }}:</b> {{ datos_venta[datos] }}</p>
            </q-card-section>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="q-pt-none">
          <q-btn flat label="Cerrar" color="red" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialog_verificar_pago">
      <q-card>
        <q-card-section class="q-pt-none q-pb-none">
          <div>
            <q-card-section class="text-center text-h6">
              <q-icon :name="icono_estado_pago" :color="color_icono_estado_pago" size="32px" />
            </q-card-section>
            <q-card-section class="q-pt-none q-pb-none text-center  text-h6">
              {{ estado_pago }}
            </q-card-section>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="q-pt-none">
          <q-btn flat label="Cerrar" color="red" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-pull-to-refresh @refresh="cargar_ventas">
      <q-card class="full-width col-12 q-ma-sm q-pa-md row justify-end">
        <div class="full-width col-12">
          <q-card class="full-width col-12 q-ma-sm q-pa-sm row gt-xs text-weight-bold" flat v-if="ventas.length != 0">
            <div class="col-12 col-sm-2">ID</div>
            <div class="col-12 col-sm-2">Hora</div>
            <div class="col-12 col-sm-2">Total</div>
            <div class="col-12 col-sm-4">Estado</div>
            <div class="col-12 col-sm-2">Acciones</div>
          </q-card>
          <div>
            <q-card class="full-width col-12 q-ma-sm q-pa-sm row" flat>
              <div class="col-12 text-center">
                <h6>{{ mensaje }}</h6>
                <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center"
                  v-if="loading" />

              </div>
            </q-card>
          </div>
          <div v-if="ventas.length != 0">
            <q-card class="full-width col-12 q-ma-sm q-pa-sm row" flat v-for="venta in ventas" :key="venta">

              <div class="col-12 col-sm-2">
                <span class="lt-sm"> N°</span> {{ venta.id }}
              </div>
              <div class="col-12 col-sm-2">
                <span class="lt-sm">Hora: </span>{{ venta.fecha_hora }}
              </div>
              <div class="col-12 col-sm-2">
                <span class="lt-sm">Total: </span>{{ store.obtener_pesos(venta.total) }}
              </div>
              <div class="col-12 col-sm-4">
                <span class="lt-sm">Estado: </span> <q-chip :color="venta.color" text-color="white" dense
                  class="q-pa-sm" :icon="(venta.icono != null) ? venta.icono : null" :label="venta.estado" />
              </div>
              <div class="col-12 col-sm-2">
                <q-btn @click="mostrar_link_pago(venta)" color="accent" icon="qr_code" label="Link" outline rounded
                  size="sm" class="q-pa-sm q-ma-sm" v-if="venta.link != null" />
                <q-btn @click="mostrar_info_comprador(venta)" color="info" icon="account_circle" label="Info" outline
                  rounded size="sm" class="q-pa-sm q-ma-sm" v-if="venta.datos != null" />
                <q-btn @click="verificar_pago(venta)" color="positive" icon="currency_exchange" label="Verificar pago"
                  outline :loading="(venta.verificando == true)" rounded size="sm" class="q-pa-sm q-ma-sm"
                  v-if="venta.link_comprobacion != null" />
              </div>
              <div class="col-12">
                <hr />
              </div>
            </q-card>
            <q-card class="full-width col-12 row" flat>

            </q-card>
          </div>
        </div>

      </q-card>
    </q-pull-to-refresh>
  </q-page>
</template>

<script setup>
// @ is an alias to /src
// import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useEPStore } from "@/store/store";
import axios from "axios";
import QrWpp from "@/QrWpp.vue";

const store = useEPStore();
const ventas = ref([])
const mostrar_link_mp = ref(false)
const dialog_info_comprador = ref(false)
const datos_venta = ref({})
const telefono = ref("")
const loading = ref(false)
const mensaje = ref("")
const estado_pago = ref("")
const icono_estado_pago = ref("")
const color_icono_estado_pago = ref("")
const dialog_verificar_pago = ref(false)

const link = ref("")

function mostrar_info_comprador(venta) {
  dialog_info_comprador.value = true;
  datos_venta.value = venta.datos
}

function mostrar_link_pago(venta) {
  link.value = venta.link;
  if (venta.telefono != null) {
    telefono.value = venta.telefono

  }
  mostrar_link_mp.value = true;
}

function verificar_pago(venta) {
  venta.verificando = true;
  axios
    .get(
      `${venta.link_comprobacion}`
    )
    .then(({ data }) => {
      venta.verificando = false;
      dialog_verificar_pago.value = true;
      estado_pago.value = data.Message
      if (data.Result == "success") {
        icono_estado_pago.value = "check_circle"
        color_icono_estado_pago.value="green"
        cargar_ventas()
      }
      if (data.Result == "error") {
        icono_estado_pago.value = "error"
        color_icono_estado_pago.value="red"
        cargar_ventas()

      }
      if (data.Result == "pending") {
        icono_estado_pago.value = "schedule"
        color_icono_estado_pago.value="primary"
      }
    })
    .catch((e) => {
      venta.verificando = false;
    });

}


function cargar_ventas(done) {
  mensaje.value = "Cargando ventas, aguarde";
  ventas.value = [];
  loading.value = true;
  if (typeof (done) == "function") {
    done()
  }
  axios
    .get(
      `${store.host}/webapp/mis_ventas?${store.obtener_credenciales()}`
    )
    .then(({ data }) => {
      ventas.value = data;
      mensaje.value = ""

      if (ventas.value == []) {
        mensaje.value = "No hay ventas para mostrar"
      }
      loading.value = false;

      // console.log(data)
    })
    .catch((e) => {
      loading.value = false;
      mensaje.value = "Ocurrió un error, vuelva a intentar."

      // console.log(typeof (done));
      // console.log(e);
    });
}

onMounted(() => cargar_ventas());
</script>
