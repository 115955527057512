<template>

    <path v-if="props.tela.nombre=='PLATEA ALTA IZQUIERDA'" ref="svgRef" :d="props.tela.path" :title="tela.nombre" :transform-origin="transform_origin" :transform="transform" :style="style" :data-original="tela.transform" :data-nombre="tela.nombre"></path>
  
</template>

<script setup>
import { ref, onMounted } from "vue";
const props = defineProps(["tela"]);
const transform=ref("")
const tela=ref(props.tela)
const style=ref("")
const transform_origin=ref("")
const svgRef = ref(null);



function getTransformString() {
  // Define a regular expression to match separators (R, S, or T)
  if (props.tela.transform == null || props.tela.transform==""){
    return "";
  }
  const separators = /R|S|T/;
// console.log(props.tela.transform);
  let sp=tela.value.transform.replaceAll(","," ").split(separators);
  if (sp.length==4){
    let scale= sp[2].split(" ") 
    let trans= sp[3].split(" ") 
    let translate=""
    translate+= `${ parseFloat(trans[0])}`
    translate+= ` ${parseFloat(trans[1])}`
    // transform_origin.value=`${parseFloat(scale[2])} ${parseFloat(scale[3])}`
    transform.value+=` rotate(${sp[1]}) `
    // transform.value+=` translate(${parseFloat(scale[2])*(-1)} ${parseFloat(scale[3])*(-1)})`
    transform.value+=` translate(${parseFloat(scale[2])*(1)} ${parseFloat(scale[3])*(1)})`
    transform.value+=` scale(${scale[0]},${scale[1]})`
    // transform.value+=` translate(${translate})`
    // transform.value+=` translate (${parseFloat(scale[2])*(-1)} ${parseFloat(scale[3])*(-1)})` //transform from scale 

  // console.log(transform.value) 

  }
  
  return sp;
}



// Example usage

// const parts = splitString(props);

//console.log(getTransformString());


</script>

<style>

</style>