import { defineStore } from "pinia";
import { ref, computed } from "vue";
import axios from "axios";
import router from "@/router/router";
// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useEPStore = defineStore("main", () => {
  const eventos = ref([]);
  const evento = ref(null);
  const funcion = ref(null);
  const loading_eventos = ref(true);
  const loading_carrito = ref(false);
  const app_version=ref("0.60")
  const force_production_api=ref(false)
  const host = ref("");
  if (process.env.NODE_ENV=='production' || force_production_api.value==true) {
    host.value = "https://api.eventpass.com.ar/test"
  } else {
    host.value = "http://192.168.18.6:8080/2.2"
    app_version.value=`${app_version.value}(Dev)`
  }
  const carrito = ref([]);
  const venta = ref(null);
  const user = ref(null);
  const limite_venta = ref("");
  const limite_venta_valor = ref(null);
  const segundos_restantes = ref(-1);
  const cuenta_atras = ref(null);
  const minutos = ref(0);
  const segundos = ref(0);
  const total_venta = ref(0);
  const cancelando_venta = ref(false);
  const linea_descarte = ref(0);
  const error_carrito = ref(false);
  const link_boton_whatsapp = ref("")


  function obtener_credenciales() {
    return new URLSearchParams({
      token: user.value.token,
      user: user.value.id,
    }).toString();
  }

  function obtener_boton_wpp(link,telefono) {
    link_boton_whatsapp.value = encodeURIComponent(
      "Link para el pago de tus entradas en EventPass: " + link
    );
    if (telefono!=null){
      link_boton_whatsapp.value = `https://api.whatsapp.com/send/?phone=54${telefono.value}&text=${link_boton_whatsapp.value}`;
    }

  }

  function carga_inicial() {
    if (is_logged()) {
      obtener_carrito();
    }
    // axios.defaults.baseURL("http://localhost:8080")
  }

  // function recargar_info_funcion(funcion_id){
  //     obtener_eventos

  // }

  function cancelar_venta() {
    if (venta.value == null) return;
    cancelando_venta.value = true;
    let datos = {
      token: user.value.token,
      user: user.value.id,
    };
    datos = new URLSearchParams(datos).toString();
    axios
      .get(`${host.value}/webapp/cancelar_venta?${datos}`)
      .then(({ data }) => {
        cancelando_venta.value = false;
        if (data.result == "ok") {
          quitarCompra("Venta cancelada");
        } else {
          limite_venta.value = "Ocurrió un error al cancelar la venta";
        }
      })
      .catch((e) => {
        limite_venta.value = "Ocurrió un error al cancelar la venta";
        cancelando_venta.value = false;

        // error.value = true;
      // console.log(e);
      });
  }
  function descartar_entrada(entrada_id) {
    let datos = {
      token: user.value.token,
      user: user.value.id,
      ubicacion: entrada_id,
    };
    linea_descarte.value = entrada_id;
    datos = new URLSearchParams(datos).toString();
    return axios
      .get(`${host.value}/webapp/descartar_linea?${datos}`)
      .then(({ data }) => {
        linea_descarte.value = 0;

        if (data.result == "ok") {
          carrito.value = carrito.value.filter(
            (x) => x.venta_ubicacion != entrada_id
          );
          if (carrito.value.length == 0) {
            cancelar_venta();
          } else {
            obtener_carrito();
          }
        } else {
          limite_venta.value = "Ocurrió un error al cancelar la entrada";
        }
      })
      .catch((e) => {
        limite_venta.value = "Ocurrió un error al cancelar la venta";
        cancelando_venta.value = false;

        // error.value = true;
      // console.log(e);
      });
  }

  function obtener_carrito() {
    if (loading_carrito.value) return;
    error_carrito.value = false

    loading_carrito.value = true;

    return axios
      .get(`${host.value}/webapp/carrito?${obtener_credenciales()}`)
      .then(({ data }) => {
        loading_carrito.value = false;
        carrito.value = data.entradas;
        venta.value = data.venta;
        if (venta.value != null) {
          total_venta.value = 0;
        // console.log(carrito.value);
          for (let tmp in carrito.value) {
            total_venta.value = total_venta.value + carrito.value[tmp].total;
          // console.log(tmp);
          }
          recalcular_segundos_restantes();
          iniciar_cuenta_atras();
        } else {
          limite_venta.value = "";
        }
      })
      .catch((e) => {
        // error.value = true;
        loading_carrito.value = false;
        error_carrito.value = true

      // console.log(e);
      });
  }

  function recalcular_segundos_restantes() {
    if (venta.value == null) {
      segundos_restantes.value = 0;
      return;
    }
    let date = new Date(
      `${venta.value.venta_fecha_limite} ${venta.value.venta_hora_limite}`
    );
    // console.log("DATE: " + `${venta.value.venta_fecha_limite} ${venta.value.venta_hora_limite}`)
    // console.log(date)
    date = date - new Date();
    segundos_restantes.value = Math.floor(date / 1000);
    if (segundos_restantes.value < 0) {
      venta.value = null;
    }
  }
  function quitarCompra(texto) {
    clearInterval(cuenta_atras.value);
    limite_venta.value = texto;
    venta.value = null;
    carrito.value = [];
    setTimeout(() => {
      limite_venta.value = ``;
    }, 3000);
  }

  function iniciar_cuenta_atras() {
  // console.log("INICIO CUENTA ATRAS EN " + segundos_restantes.value);
    clearInterval(cuenta_atras.value);
    if (segundos_restantes.value < 1) {
      cancelar_venta();
      quitarCompra(`¡Se acabó el tiempo!`);
      return;
    }
    cuenta_atras.value = setInterval(() => {
      if (segundos_restantes.value % 5 == 0) {
        recalcular_segundos_restantes();
      }
      segundos_restantes.value -= 1;
      // console.log("CUENTA ATRAS:"+segundos_restantes.value);
      minutos.value = Math.floor(segundos_restantes.value / 60);
      segundos.value = segundos_restantes.value - minutos.value * 60;
      if (segundos.value < 10) segundos.value = `0${segundos.value}`;
      limite_venta.value = `Límite de tiempo ${minutos.value}:${segundos.value}`;
      if (segundos_restantes.value < 1) {
        cancelar_venta();
        quitarCompra(`¡Se acabó el tiempo!`);
        // clearInterval(cuenta_atras.value)
      }
    }, 1000);
  }

  function obtener_eventos() {
    loading_eventos.value = true;
    eventos.value = [];

    eventos;
    let datos = {
      token: user.value.token,
      user: user.value.id,
      limit: 100,
      offset: 0,
      nombre: "",
      fcm_token: "",
      type: "boleteria",
    };
    // console.log(datos)
    datos = new URLSearchParams(datos).toString();
    return axios
      .get(host.value + `/app/listar_eventos?${datos}`)
      .then(({ data }) => {
        // console.log(data)
        loading_eventos.value = false;
        eventos.value = data.eventos;
        // console.log(eventos.value)
      })
      .catch((er) => {
      // console.log(er);
        router.push({ name: "error" });
      });
  }

  function is_logged() {
    let us = localStorage.getItem("user");
    if (us == null) {
      return false;
    } else {
      user.value = JSON.parse(us);
      // console.log(user.value)
      return true;
    }
  }

  function saveCredentials(cr) {
    let credenciales = {
      id: cr.user_id,
      name: cr.user_name,
      user_type_name: cr.user_tipo_nombre,
      user_type_id: cr.user_tipo,
      user_email: cr.user_email,
      token: cr.user_token,
    };
    localStorage.setItem("user", JSON.stringify(credenciales));
    is_logged();
  }

  function login(username, password) {
    return axios.post(
      `${host.value}/login`,
      `username=${username}&password=${password}`
    );

    //    return axios.post(`${host.value}/login`,{username: username,password:password})
  }

  function get_fecha(funcion) {
    let fecha = "";
    fecha = funcion.fecha;

    if (funcion.fecha_personalizada != "") {
      return funcion.fecha_personalizada;
    }
    let p = funcion.fecha.split("-");
    fecha = `${p[2]}/${p[1]}/${p[0]}`;
    if (funcion.multifecha == "1") {
      fecha = `Del ${fecha} al ${funcion.fecha_hasta}`.toUpperCase();
    } else {
      let date = new Date(funcion.fecha + " 18:00:00");
      fecha = `${dias[date.getDay()]} ${date.getDate()} DE ${meses[date.getMonth()]
        } DE ${date.getFullYear()}`.toUpperCase();
    }
    return fecha;
  }
  const dias = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  function obtener_pesos(price) {
    return price.toLocaleString("es-ar", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
    });
  }

  const obtener_categorias = computed(() =>
    Array.from(eventos.value, (ev) => ({
      nombre: ev.categoria,
      id: ev.categoria_id,
    })).filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
  );
  return {
    quitarCompra,
    descartar_entrada,
    linea_descarte,
    cancelando_venta,
    cancelar_venta,
    user,
    eventos,
    evento,
    carga_inicial,
    obtener_categorias,
    host,
    funcion,
    get_fecha,
    obtener_pesos,
    carrito,
    login,
    saveCredentials,
    obtener_eventos,
    total_venta,
    obtener_carrito,
    limite_venta,
    limite_venta_valor,
    venta,
    loading_carrito,
    loading_eventos,
    obtener_credenciales,obtener_boton_wpp,app_version
  };
});
