import "./styles/quasar.sass";
import lang from "quasar/lang/es.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {},
  lang: lang,
};
