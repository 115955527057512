<template>
    <q-card class="q-pa-md">
        <q-card-section class="q-pt-none q-pb-none">
            <div>
                <!-- <h6 class="q-my-md"></h6> -->
                <q-card-section class="row  q-pa-none">
                    <div class="text-subtitle1">Código QR para el pago</div>
                </q-card-section>
                <div v-if="link != ''">
                    <q-card :dark="false" class="q-pa-sm row flex-center">
                        <VueQRCodeComponent :text="link" :size="150"></VueQRCodeComponent>
                    </q-card>
                    <h6 class="q-my-md">
                        <!-- Alternativamente puede enviar el link por WhatsApp haciendo click en
                        el siguiente botón -->
                    </h6>
                    <q-btn @click="abrir_link_wpp()" color="positive" icon="send_to_mobile" label="WhatsApp" size="sm"
                        v-if="wpp_disponible" class="q-mb-md q-mr-md" />
                    <q-btn @click="compartir()" color="orange" icon="share" label="Compartir" class="q-mb-md q-mr-md"
                        v-if="puedeCompartir" size="sm" />
                    <q-btn @click="copiar_al_portapapeles()" :color="(copiado) ? 'green' : 'blue'" size="sm"
                        :icon="(copiado) ? 'done' : 'content_copy'" :label="(!copiado) ? 'Copiar' : '¡Copiado!'"
                        class="q-mb-md" />
                    <q-input v-show="copiando" ref="input_copy" readonly rounded outlined v-model="link"
                        label="Link de pago" class="q-ma-md" />
                </div>
                <div v-else>
                    <div class="text-subtitle1">No se recibió el link de pago</div>

                </div>
            </div>
        </q-card-section>

        <q-card-actions align="right" class="q-pt-none q-pb-none">
            <q-btn flat label="Cerrar" color="red" v-close-popup />
        </q-card-actions>
    </q-card>
</template>

<script setup>
import { ref, onMounted } from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
const props = defineProps(["link", "telefono"]);
const link = ref("")
const copiado = ref(false)
const copiando = ref(false)
const input_copy = ref()
const link_boton_whatsapp = ref("")
const wpp_disponible = ref(false);
const ancho = ref(256)
const puedeCompartir = ref(false)
const texto_compartir = ref("");

function abrir_link_wpp() {
    window.open(link_boton_whatsapp.value);
}

function compartir() {
    navigator.share({
        title: "Compartir link de pago de EventPass",
        text: texto_compartir.value,
    })

}

function copiar_al_portapapeles() {
    copiando.value = true;
    copiado.value = false;

    setTimeout(() => {
        input_copy.value.focus();
        input_copy.value.select();
        document.execCommand('copy');
        copiando.value = false;
        copiado.value = true;
        setTimeout(() => {
            copiado.value = false;

        }, 2000);
    }, 1);
}

onMounted(() => {
    // console.log(props)
    if (typeof (navigator.share) == "function") {
        puedeCompartir.value = true;
    }
    ancho.value = screen.width;
    link.value = props.link
    texto_compartir.value = "Link para el pago de tus entradas en EventPass: " + props.link

    link_boton_whatsapp.value = encodeURIComponent(
        texto_compartir.value
    );
    if (props.telefono != null && props.telefono != "") {
        wpp_disponible.value = true;
        link_boton_whatsapp.value = `https://api.whatsapp.com/send/?phone=54${props.telefono}&text=${link_boton_whatsapp.value}`;
    }
});




</script>
