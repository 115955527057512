<template>
  <q-page class=" ">
    <div class="column q-pa-lg">
      <div class="row justify-center items-center">
        <!-- <q-card square dark class="shadow-24 text-white" style="width:300px;height:540px;"> -->
        <q-card
          square
          dark
          class="shadow-24 text-white col-xl-5 col-lg-7 col-md-8 col-sm-10 col-xs-12 q-pb-md"
        >
          <q-card-section style="background-color: #e67e22">
            <q-img
              src="https://www.eventpass.com.ar/EventPass/img/logoEventpass_inicio.png"
            >
            </q-img>
          </q-card-section>
          <q-card-section>
            <h4 class="text-h5 q-my-md">Oops, ocurrió un error.</h4>

            <h6 class="text-center q-ma-sm">
              Asegúrese de estar conectado a internet
            </h6>
          </q-card-section>

          <q-card-actions class="q-px-lg">
            <q-btn
              unelevated
              size="lg"
              color="primary"
              @click="router.go(-1)"
              class="full-width text-white"
              label="Volver"
            />
          </q-card-actions>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
// import { ref, onBeforeMount } from "vue";
// import { useEPStore } from "@/store/store";
// const store = useEPStore();

const router = useRouter();
// const route = useRoute();
// const username = ref("");
// const password = ref("");
// const passwordFieldType = ref("password");
// const visibilityIcon = ref("visibility");
// const visibility = ref(false);
// const error = ref("");
// const login_in = ref(false);

// function submit() {
//   login_in.value = true;
//   store
//     .login(username.value, password.value)
//     .then(({ data }) => {
//       login_in.value = false;
//     // console.log(data);
//       if (data.request == "ok") {
//         store.saveCredentials(data.user_information);
//         router.push({ name: "home" });
//       } else {
//         error.value = data.message;
//       }
//     })
//     .catch((er) => {
//       login_in.value = false;
//       error.value = "Oops, ocurrió un error.";
//     });
// }

// function show_error(error_msg) {
//   error.value = error_msg;
//   setTimeout(() => {
//     error.value = "";
//   }, 4000);
// }

// function switchVisibility() {
//   visibility.value = !visibility.value;
//   passwordFieldType.value = visibility.value ? "text" : "password";
//   visibilityIcon.value = visibility.value ? "visibility_off" : "visibility";
// }
</script>
