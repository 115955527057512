<template>
  <div class="home">
    <q-page class="row items-start">
      <q-card class="col-12 q-ma-sm q-pa-xs col-md-4" v-if="mostrar_imagenes">
        <q-img class="q-mb-md col-12" :src="imagen" spinner-color="white" />
        <q-separator />
        <q-card class="row flex-center">
          <div class="q-ma-md col-2" v-for="imagenn in imagenes" :key="imagenn">
            <q-img
              :style="imagenn == imagen ? margin : null"
              :src="imagenn"
              @click="imagen = imagenn"
              spinner-color="white"
            />
          </div>
        </q-card>
      </q-card>
      <q-card class="col-12 col-md-7 q-ma-sm">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="evento" label="Fechas" icon="event" />
          <q-tab name="info" label="Más información" icon="info" />
        </q-tabs>
        <q-separator />
        <!-- <q-card-section> -->
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="info" >
              <div v-if="loading_evento">
                <q-skeleton
                  v-for="tmp in [1, 1, 1, 1, 1, 1, 1, 1, 1]"
                  type="text"
                  class="q-mb-md"
                  :key="tmp"
                />
              </div>
              <div v-else>
                <div class="text-h6">{{ evento.evento_nombre }}</div>
                <div v-html="evento.evento_descripcion"></div>
              </div>
            </q-tab-panel>

            <q-tab-panel name="evento">
              <!-- <q-card class="col-10 q-pa-md"> -->
                <div class="text-h4 text-center">FECHAS</div>
                <q-separator />
                <div v-if="loading_funciones">
                  <q-skeleton
                    v-for="tmp in [1, 1, 1, 1]"
                    type="rect"
                    height="50px"
                    class="q-mb-md"
                    :key="tmp"
                  />
                </div>
                <div v-else>
                  <div v-for="funcion in funciones" class="" :key="funcion">
                    <q-card class="col-12 q-my-sm q-mr-lg row items-center" flat>
                      <div class="col-12 col-lg-4 q-pa-md">
                        <q-card
                          dark
                          class="col-3 text-center q-pa-xs bg-primary"
                        >
                          <div class="text-h6">
                            {{ funcion.fecha }}
                          </div>
                        </q-card>
                      </div>
                      <div class="col-12 col-lg-6 q-pa-md">
                        <p>
                          <q-icon name="watch_later" /> <b>Hora:</b>
                          {{ funcion.hora }} hs
                        </p>
                        <p>
                          <q-icon name="public" /> <b>Localidad:</b>
                          {{ funcion.localidad }}
                        </p>
                        <p>
                          <q-icon name="place" /> <b>Lugar:</b>
                          {{ funcion.lugar }}
                        </p>
                      </div>
                      <div class="col-12 col-lg-2 text-center">
                        <q-btn
                          @click="
                            store.funcion = funcion;
                            router.push({
                              name: 'funcion',
                              params: {
                                evento_slug: props.evento_slug,
                                funcion_id: funcion.id,
                              },
                            });
                          "
                          class="col-12 q-mr-sm"
                          color="positive"
                          icon="local_activity"
                          label="SELECCIONAR"
                        />
                      </div>
                    </q-card>
                    <q-separator />
                  </div>
                </div>
              <!-- </q-card> -->
            </q-tab-panel>
          </q-tab-panels>
        <!-- </q-card-section> -->
        <!-- <q-separator /> -->
        <q-card-section> </q-card-section>
      </q-card>
    </q-page>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted, defineProps } from "vue";
import { useEPStore } from "@/store/store";
import axios from "axios";
const props = defineProps(["evento_slug"]);
const router = useRouter();
const route = useRoute();
const store = useEPStore();
const evento = ref(store.evento);
const img_host = "https://www.eventpass.com.ar/data/ep_evento/";
const imagenes = [];
const mostrar_imagenes = ref(true);
const slug = route.params.evento_slug;
const imagen = ref("");
const loading_evento = ref(true);
const loading_funciones = ref(true);
const loaded_images = ref(false);
function recargar_info() {
  if (!loaded_images.value) {
    if (evento.value.img_1 != "" && imagenes[0] != evento.value.img_1)
      imagenes.push(img_host + evento.value.img_1);
    if (evento.value.img_2 != "" && imagenes[1] != evento.value.img_2)
      imagenes.push(img_host + evento.value.img_2);
    if (evento.value.img_3 != "" && imagenes[2] != evento.value.img_3)
      imagenes.push(img_host + evento.value.img_3);
    if (imagenes.length == 0) {
      mostrar_imagenes.value = false;
    } else {
      mostrar_imagenes.value = true;
      imagen.value = imagenes[0];
      loaded_images.value = true;
    }
  }
}

if (evento.value != null) {
  loading_evento.value = false;
  // loaded_images.value = true;
  recargar_info();
}
const funciones = ref([
]);

onMounted(() => {

  store.funcion=null
});
axios
  .get(
    `${store.host}/webapp/detalle_evento?evento=${slug}&already_loaded=${
      evento.value != null
    }&${store.obtener_credenciales()}`
  )
  .then(({ data }) => {
    evento.value = data.evento;
    loading_evento.value = false;
    funciones.value = data.funciones;
    loading_funciones.value = false;
    store.evento = evento.value;
    recargar_info();
  });

const margin = { border: "5px solid orange" };
const tab = ref("evento");
// const slide = ref(1);

// setTimeout(() => {
// }, 500);
// setTimeout(() => {
// }, 1500);
</script>
