<template>
  <q-page class=" ">
    <div class="column q-pa-lg">
      <div class="row justify-center items-center">
        <!-- <q-card square dark class="shadow-24 text-white" style="width:300px;height:540px;"> -->
        <q-card
          square
          dark
          class="shadow-24 text-white col-xl-5 col-lg-7 col-md-8 col-sm-10 col-xs-12"
        >
          <q-card-section style="background-color: #e67e22">
            <q-img
              src="https://www.eventpass.com.ar/EventPass/img/logoEventpass_inicio.png"
            >
            </q-img>
          </q-card-section>
          <q-card-section>
            <h4 class="text-h5 q-my-md">Inicio de sesión</h4>
            <q-form class="q-px-sm q-pt-sm text-white">
              <q-input square v-model="username" label="Nombre de usuario">
                <template v-slot:prepend>
                  <q-icon name="person" />
                </template>
              </q-input>

              <q-input
                square
                v-model="password"
                :type="passwordFieldType"
                @keypress.enter="submit"
                label="Contraseña"
              >
                <template v-slot:prepend>
                  <q-icon name="lock" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="visibilityIcon"
                    @click="switchVisibility"
                    class="cursor-pointer"
                  />
                </template>
              </q-input>
            </q-form>
            <h6 class="text-center text-red q-ma-sm">{{ error }}</h6>
          </q-card-section>

          <q-card-actions class="q-px-lg">
            <q-btn
              unelevated
              size="lg"
              color="secondary"
              @click="submit"
              class="full-width text-white"
              :loading="login_in"
              :disable="login_in"
              label="Iniciar sesión"
            />
          </q-card-actions>
          <q-card-section class="text-center q-pa-sm">
            <!-- <p class="text-grey-6">Olvidé mi contraseña</p> -->
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { useEPStore } from "@/store/store";
const store = useEPStore();

const router = useRouter();
// const route = useRoute();
const username = ref("");
const password = ref("");
const passwordFieldType = ref("password");
const visibilityIcon = ref("visibility");
const visibility = ref(false);
const error = ref("");
const login_in = ref(false);

onBeforeMount(() => {
  store.user = null;
  localStorage.removeItem("user");
});

function submit() {
  login_in.value = true;
  store
    .login(username.value, password.value)
    .then(({ data }) => {
      login_in.value = false;
    // console.log(data);
      if (data.request == "ok") {
        store.saveCredentials(data.user_information);
        router.push({ name: "home" });
      } else {
        error.value = data.message;
      }
    })
    .catch((er) => {
    // console.log(er);
      login_in.value = false;
      error.value = "Oops, ocurrió un error.";
    });
}

// function show_error(error_msg) {
//   error.value = error_msg;
//   setTimeout(() => {
//     error.value = "";
//   }, 4000);
// }

function switchVisibility() {
  visibility.value = !visibility.value;
  passwordFieldType.value = visibility.value ? "text" : "password";
  visibilityIcon.value = visibility.value ? "visibility_off" : "visibility";
}
</script>
