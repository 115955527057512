<template>
  <image :width="props.tela.width" :height="props.tela.height" :href="image" >

  </image>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps(["tela","url"]);
// console.log(props.tela)
const image=ref("")
image.value=`${props.url}${props.tela.src}`

</script>



<style>

</style>