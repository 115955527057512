import script from "./SinMapa.vue?vue&type=script&setup=true&lang=js"
export * from "./SinMapa.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QChip,QAvatar,QTooltip,QCardActions,QIcon,QCircularProgress});qInstall(script, 'directives', {ClosePopup});
