<template>
  <div class="home">
    <q-page class="" v-if="store.evento != null && store.funcion != null">
      <q-pull-to-refresh @refresh="refresh">
        <q-card class="row items-start">
          <div class="col-12 q-mt-sm">
            <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="justify"
              narrow-indicator>
              <q-tab name="funcion" label="Sectores" icon="confirmation_number" />
              <q-tab name="lugar" label="Lugar" icon="place" />
            </q-tabs>
            <q-separator />
            <q-card-section>
              <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="funcion">
                  <div class="text-h6 q-my-sm">{{ store.evento.evento_nombre }}</div>
                  <p class="q-my-sm">
                    Lugar: <b>{{ funcion.lugar }}</b>
                  </p>
                  <div class="text-orange text-h6 text-weight-bold">
                    {{ funcion.fecha }}
                  </div>
                  <div v-if="!error">
                    <div v-if="loading_sectores">
                      <div class="text-h6 text-center">
                        Cargando sectores, aguarde...
                      </div>
                      <br />
                      <q-skeleton v-for="tmp in [1, 2, 3, 4]" type="rect" class="q-mb-md" :key="tmp" />
                    </div>
                    <div v-else>
                      <div v-if="sectores.length==0">
                        <div class="text-h6 text-center">
                          No hay sectores disponibles para la venta
                        </div>
                      </div>
                      <div v-else>
                        <con-mapa :sectores="sectores" :mapa="mapa" v-if="tiene_mapa" :url="url"></con-mapa>
                        <div class="text-h6 text-center">
                          Seleccione un sector para continuar
                        </div>
                        <sin-mapa :sectores="sectores"></sin-mapa>

                      </div>
                    </div>
                  </div>
                  <div v-else class="justify-center row">
                    <div class="text-h6 text-center col-12 q-a-12">
                      Hubo un error de red, intente nuevamente
                    </div>
                    <br /><br />
                    <q-btn class="primary text-center" @click="obtener_sectores()">Reintentar <q-icon
                        name="refresh" /></q-btn>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="lugar">
                  <div class="text-h6">{{ funcion.lugar }}</div>

                  <iframe width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" v-if="funcion.latitud != null && funcion.longitud!= null"
                    :src="`https://maps.google.com/maps?q=${funcion.latitud},${funcion.longitud}&hl=es;z=14&output=embed`"></iframe>
                </q-tab-panel>
              </q-tab-panels>
            </q-card-section>
          </div>
        </q-card>
      </q-pull-to-refresh>
    </q-page>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { useRouter,  } from "vue-router";
import { ref, onMounted, defineProps } from "vue";
import { useEPStore } from "@/store/store";
import SinMapa from "./SinMapa.vue";
import ConMapa from "./ConMapa.vue";
import axios from "axios";
const store = useEPStore();


const props = defineProps(["funcion_id", "evento_slug"]);
const router = useRouter();
const loading_sectores = ref(true);
const funcion = ref(store.funcion);
const evento = ref(store.evento);
const sectores = ref([]);
const error = ref(false);
const tab = ref("funcion");
// const step = ref(1);
const tiene_mapa=ref(false)
const mapa=ref("");
const url=ref("")

onMounted(() => {
  if (funcion.value == null || store.evento == null) {
    router.push({ name: "evento", params: { evento_slug: props.evento_slug } });
    return;
  }
  obtener_sectores();
});

function obtener_sectores() {
  error.value = false;
  loading_sectores.value = true;
  return axios
    .get(`${store.host}/webapp/listar_sectores?funcion_id=${funcion.value.id}&${store.obtener_credenciales()}`)
    .then(({ data }) => {
      loading_sectores.value = false;
      sectores.value = data.sectores;
      if(data.tiene_mapa){
        mapa.value=data.mapa;
      }
      tiene_mapa.value=data.tiene_mapa;
      if (data.url_datos != null){
        url.value=data.url_datos;
      }
      // error.value=true;
    })
    .catch((e) => {
    // console.log(e);
      error.value = true;
    });
}

function refresh(done) {
  // setTimeout(() => {
  //     done();
  // }, 1000);
  obtener_sectores().then((data) => {
    done();
  // console.log(data);
  });
}
</script>
