import script from "./Ventas.vue?vue&type=script&setup=true&lang=js"
export * from "./Ventas.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QChip from 'quasar/src/components/chip/QChip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QCard,QCardSection,QCardActions,QBtn,QIcon,QPullToRefresh,QCircularProgress,QChip});qInstall(script, 'directives', {ClosePopup});
