import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/router.js";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import update from './mixins/update'
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(Quasar, quasarUserOptions);
app.mixin(update)
app.mount("#app");
