import script from "./FuncionView.vue?vue&type=script&setup=true&lang=js"
export * from "./FuncionView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QPullToRefresh,QCard,QTabs,QTab,QSeparator,QCardSection,QTabPanels,QTabPanel,QSkeleton,QBtn,QIcon});
