<template>
  <rect x="0" y="0" width="100" height="100" r="0" rx="0" ry="0" fill="#1f1be6" stroke="#000000" transform="matrix(3.7846,0,0,3.7846,110.7692,127.7692)" stroke-width="0.26422764227642276" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></rect>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps(["tela"]);
const tela=ref(props["tela"])
</script>

<style>

</style>