<template>
  <q-dialog :persistent="loading" v-model="sin_ubicacion">
    <q-card style="width: 500px; max-width: 80vw">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ sectorActual.nombre }}</div>
        <q-space />
        <q-btn :disabled="loading" icon="close" @click="
          mensaje = '';
        reiniciar_cantidad();
        " flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="q-pt-none flex row">
        <div class="col-12">
          <br />
          <p>Indique la cantidad de entradas</p>
        </div>
        <div class="col-12 col-sm-4  text-center items-center flex-center row">
          <q-btn size="sm" color="accent" icon="remove" :disabled="cantidad_entradas <= 1 || loading"
            @click="cambiar_cantidad(false)" />
          <q-btn :disabled="loading" flat :ripple="false">{{
            cantidad_entradas
            }}</q-btn>
          <q-btn size="sm" :disabled="loading" color="accent" icon="add" @click="cambiar_cantidad(true)" />
        </div>
        <div class="text-h6 text-center col-12 col-sm-4 ">
          {{ store.obtener_pesos(cantidad_entradas * sectorActual.precio) }}
        </div>
        <div class="col-12 q-mt-sm">
          <q-chip v-show="mensaje != ''">
            <q-avatar :icon="errores ? 'close' : 'check'" :color="errores ? 'red' : 'green'" text-color="white" />
            <div class="ellipsis">
              {{ mensaje }}
              <q-tooltip>{{ mensaje }}</q-tooltip>
            </div>
          </q-chip>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="text-teal">
        <!-- <q-btn flat label="Cerrar" v-close-popup /> -->
        <q-btn outline v-if="!loading" @click="anadir_al_carrito()" :disabled="loading" color="primary" class="full-width">
          Añadir al carrito
          <q-icon name="add_shopping_cart" />
        </q-btn>
        <q-btn v-else :disabled="loading" color="grey" class="full-width">Añadiendo, aguarde...
          <q-circular-progress indeterminate rounded class="q-ml-md" />
        </q-btn>
        <q-btn v-if="!boton_carrito_desactivado" :flat="boton_carrito_desactivado"
          @click="router.push({ name: 'carrito' })" :disabled="(loading || boton_carrito_desactivado)" color="green"
          class="full-width q-mt-md">
          Ir al carrito
          <q-icon name="shopping_cart_checkout" />
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog :persistent="loading" v-model="con_ubicacion" style="width: 900px; max-width: 80vw">

    <mapa-ubicaciones :sector="seccion"></mapa-ubicaciones>

  </q-dialog>

  <q-btn :disabled="sector.cantidad == 0" block :class="get_clase(sector)" v-for="sector in props.sectores"
    @click="seleccionar_sector(sector)" :key="sector.id">
    <div class="col-12 col-sm-4">{{ sector.nombre }}</div>
    <div class="col-12 col-sm-4">{{ store.obtener_pesos(sector.precio) }}</div>
    <div class="col-12 col-sm-4">
      <div v-if="sector.cantidad != 0">
        SELECCIONAR
        <q-icon size="sm" :name="(sector.tiene_ubicacion == true) ? 'chair' : 'confirmation_number'" />
        <q-icon name="arrow_forward" />
      </div>
      <div v-else>
        AGOTADO
        <q-icon size="sm" name="close" />
      </div>
    </div>
  </q-btn>
</template>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useEPStore } from "@/store/store";
import axios from "axios";
import MapaUbicaciones from "./MapaUbicaciones.vue"

const store = useEPStore();
const router = useRouter();
const props = defineProps(["sectores"]);
const sin_ubicacion = ref(false);
const con_ubicacion = ref(false);
const sectorActual = ref(null);
const cantidad_entradas = ref(1);
const loading = ref(false);
const errores = ref(false);
const mensaje = ref("");
const seccion = ref({})
const boton_carrito_desactivado = ref(true);

function seleccionar_sector(sector) {
  sectorActual.value = sector;
  if (sector.tiene_ubicacion == true) {
    seccion.value = sector;
    con_ubicacion.value = true;
  } else {
    sin_ubicacion.value = true;

  }
}

// function cerrar_dialogo() {
//   dialog.value = false;
// }

function get_clase(sector) {
  let clase = "bg-orange-9";
  if (sector.cantidad == 0) {
    clase = "bg-grey-9";
  }
  return (
    "full-width col-12  q-ma-md   row  text-white text-center text-weight-bold " +
    clase
  );
}

function cambiar_cantidad(sumar) {
  if (!sumar) {
    if (cantidad_entradas.value <= 1) return;
    cantidad_entradas.value--;
  } else {
    cantidad_entradas.value++;
  }
}

function anadir_al_carrito() {
  loading.value = true;
  mensaje.value = "";
  errores.value = false;
  axios
    .post(
      `${store.host}/webapp/agregar_entradas`,
      `token=${store.user.token}&user=${store.user.id}&verificar_ubicaciones=false&funcion=${store.funcion.id}&ubicaciones=[{"seccion":"${sectorActual.value.id}","ubicacion":"0","cantidad":"${cantidad_entradas.value}","funcion_id":${store.funcion.id}}]`
    )
    .then(({ data }) => {
      //{"Result":"error","TotalRecordCount":0,"Message":"No se pueden seleccionar esas ubicaciones, ya fueron ocupadas"}
      //{"Result":"ok","TotalRecordCount":0,"Record":{"venta_id":135462,"evento_id":0,"funcion_id":0,"venta_fecha":"2023-06-15","venta_hora":"20:03:43","usuario_id":255,"venta_estado":1,"venta_tipo":"boleteria","entrega_id":0,"transaccion_nps_id":0,"venta_envio_id":0,"venta_fpago":0,"cupon_id":0,"reserva_id":0,"venta_usuario_impresion":0,"venta_fecha_limite":"2023-06-15","venta_hora_limite":"20:18:43","tiempo_extendido":0,"venta_bloqueada":0},"Message":"135462"}

      if (data.Result == "ok") {
        if (cantidad_entradas.value == 1) {
          mensaje.value = `¡${cantidad_entradas.value} entrada añadida exitosamente!`;
        } else {
          mensaje.value = `¡${cantidad_entradas.value} entradas añadidas exitosamente!`;
        }
        store.obtener_carrito();
        boton_carrito_desactivado.value = false;

      } else {
        mensaje.value =
          "Lo sentimos, no hay disponibilidad en el sector seleccionado";
        errores.value = true;
      }
      // console.log(data);
      loading.value = false;
    }).catch((e) => {
      loading.value = false;

      mensaje.value =
        "Ocurrió un error, asegurate de estar conectado a internet";
      errores.value = true;
    });
}

function reiniciar_cantidad() {
  cantidad_entradas.value = 1;
}
</script>
