<template>
  <q-page class="flex-center">
    <q-pull-to-refresh @refresh="refresh">
    <div class="home row">
      <div class="col-12 col-lg-12">
        <div class="q-pa-sm row items-start justify-evenly q-gutter-sm">
          <q-card
            v-if="store.loading_eventos"
            class="col-12 flex-center items-center text-center q-pa-lg"
          >
            <h5 class="q-ma-sm">Cargando eventos, aguarde</h5>
            <q-circular-progress
              indeterminate
              rounded
              class="q-ma-md"
              size="lg"
            />
          </q-card>
          <q-card
            v-if="!store.loading_eventos && filtrar_eventos.length==0"
            class="col-12 flex-center items-center text-center q-pa-lg"
          >
            <h5 class="q-ma-sm">No hay eventos disponibles a la venta</h5>
            <q-img
            height="100px"
            fit="contain"
              src="/img/no_events.svg"
            />
          </q-card>
          <q-card
            class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-5"
            v-for="evento in filtrar_eventos"
            :key="evento"
            @click="
                  store.evento = evento;
                  router.push({
                    name: 'evento',
                    params: { evento_slug: evento.evento_slug },
                  });
                "
          >
            <q-img :src="evento.evento_imagen" />
            <q-badge
              v-if="evento.destacado == 1"
              class="absolute bg-orange"
              style="top: 0; right: -8px; transform: translateY(70%)"
              >EVENTO DESTACADO</q-badge
            >

            <q-card-section>
              <div class="row no-wrap items-center">
                <div class="col text-h8 text-weight-bold">
                  {{ evento.evento_nombre }}
                </div>
              </div>
            </q-card-section>

            <q-space></q-space>
            <!-- <q-separator />
            <q-card-actions>
              <q-btn
             
                flat
                color="primary"
              >
                Ver detalles
              </q-btn>
            </q-card-actions> -->
          </q-card>
        </div>
      </div>
    </div>
</q-pull-to-refresh>

  </q-page>
</template>
<style lang="sass" scoped>
.my-card
  //max-width: 230px
</style>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref, computed, onMounted } from "vue";
import { useEPStore } from "@/store/store";
const categoria_id = ref(0);
const store = useEPStore();
// const img_host = "https://www.eventpass.com.ar/data/ep_evento/";
// const loading_eventos = ref(true);
const router = useRouter();
// const route = useRoute();
const filtrar_eventos = computed(() =>
  categoria_id.value == 0
    ? store.eventos
    : store.eventos.filter((ev) => ev.categoria_id == categoria_id.value)
);

onMounted(() => {
  store.evento=null;
  store.funcion=null
  store.obtener_eventos();
});

function refresh(done){
  store.obtener_eventos();
  done()
}
</script>
