<template>
  <q-page class=" ">
    <q-pull-to-refresh @refresh="obtener_carrito">
      <q-dialog v-model="alerta_descarte">
        <q-card>
          <q-card-section>
            <div class="text-h6">ATENCIÓN</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            ¿Está seguro de querer eliminar la entrada del carrito?
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="SI" color="primary" v-close-popup @click="store.descartar_entrada(linea_descarte)" />
            <q-btn flat label="NO" outline v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-card class="full-width col-12 q-ma-sm q-pa-md row justify-end">
        <q-btn @click="store.cancelar_venta" color="red" icon="cancel" label="Cancelar venta" outline size="small"
          class="" flat v-if="store.venta != null && !store.error_carrito" />
        <div class="full-width col-12">
          <q-card class="full-width col-12 q-ma-sm q-pa-sm row gt-xs text-weight-bold" flat>
            <div class="col-12 col-sm-1"></div>
            <div class="col-12 col-sm-5">Evento</div>
            <div class="col-12 col-sm-3">Sector</div>
            <div class="col-12 col-sm-1">Fila</div>
            <div class="col-12 col-sm-1">Butaca</div>
            <div class="col-12 col-sm-1">Valor</div>
          </q-card>
          <div v-if="store.carrito.length == 0">
            <q-card class="full-width col-12 q-ma-sm q-pa-sm row" flat>
              <div class="col-12 text-center">
                <h3>No hay entradas en tu carrito</h3>
              </div>
            </q-card>
          </div>
          <div v-if="!store.carrito.length == 0">
            <q-card class="full-width col-12 q-ma-sm q-pa-sm row" flat v-for="entrada in store.carrito" :key="entrada">
              <div class="col-12 col-sm-1">
                <q-btn :loading="store.linea_descarte == entrada.id" :disable="store.linea_descarte != 0" flat round
                  color="red" icon="delete" @click="
                    alerta_descarte = true;
                  linea_descarte = entrada.id;
                  " />
              </div>
              <div class="col-12 col-sm-5">
                <span class="lt-sm">Evento: </span>
                <b>{{ entrada.evento }}</b>
                <br />{{ entrada.funcion }}
              </div>
              <div class="col-12 col-sm-3">
                <span class="lt-sm">Sector: </span>{{ entrada.sector }}
              </div>
              <div class="col-12 col-sm-1">
                <span class="lt-sm">Fila: </span>{{ entrada.fila }}
              </div>
              <div class="col-12 col-sm-1">
                <span class="lt-sm">Butaca: </span>{{ entrada.butaca }}
              </div>
              <div class="col-12 col-sm-1">
                <span class="lt-sm">Valor: </span>{{ store.obtener_pesos(entrada.total) }}
                <q-badge color="blue" rounded v-if="entrada.descuento" >
                    DESCUENTO
                  </q-badge>

              </div>
              <div class="col-12">
                <hr />
              </div>
            </q-card>
            <q-card class="full-width col-12 row" flat>
              <div class="col-12 text-right">
                <h5>Total: {{ store.obtener_pesos(store.total_venta) }}</h5>
              </div>
            </q-card>
          </div>
        </div>
        <q-btn @click="router.push({ name: 'checkout' })" color="orange" label="Siguiente" v-if="store.venta != null" />
      </q-card>
    </q-pull-to-refresh>
  </q-page>
</template>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useEPStore } from "@/store/store";
const store = useEPStore();
const router = useRouter();
// const dialog = ref(false);
// const loading = ref(false);
// const errores = ref(false);
// const mensaje = ref("");
// const entradas = ref(store.carrito);
const alerta_descarte = ref(false);
const linea_descarte = ref(0);

function obtener_carrito(done) {
  store.obtener_carrito().then(() => done());
}

// function descartar_entrada() {
//   store.descartar_entrada.then(() => {});
// }

onMounted(() => store.obtener_carrito());
</script>
