<template>
    <q-card class="q-pa-md">
        <div v-if="loading_ubicaciones">
            <div class="col-12 col-sm-4 text-center">
                <h6 v-if="error" style="">Revise su conexión y vuelva a intentar.</h6>
                <h6 v-if="!error" style="">Cargando ubicaciones... aguarde</h6>
            </div>
        </div>
        <div v-if="filas.length != 0">
            <q-card-section>
                <div class="col-12 col-sm-4 ">
                    <h5 style="">{{ sector.nombre }} </h5>
                    <h6 style="">{{ store.obtener_pesos(sector.precio) }}</h6>
                    <h6 style="" class="text-center">Seleccione las butacas</h6>
                    <div class="celda libre q-pr-md" style="display:inline-block"></div><span
                        class=" q-pr-md">Libre</span>
                    <div class="celda ocupada" style="display:inline-block"></div><span class=" q-pr-md">Ocupada</span>
                    <div class="celda nodisponible" style="display:inline-block"></div><span class=" q-pr-md">No
                        disp.</span>
                </div>
            </q-card-section>
            <q-card-section>
                <div class="col-12 scroll">
                    <table style="width:100%">
                        <tr v-for="(ubicaciones, fila) in filas" :key="fila">
                            <td>{{ fila }}</td>
                            <td v-for="ubicacion in ubicaciones" :key="ubicacion">
                                <div class="celda" @contextmenu.prevent="" :class="obtener_clases(ubicacion)"
                                    v-if="ubicacion['id'] != -1" @click="seleccionar_ubicacion(ubicacion)">
                                    <q-tooltip>
                                        <h6 style="">Fila: {{ fila }}, Butaca: {{ ubicacion["butaca"] }}</h6>
                                    </q-tooltip>
                                </div>
                                <div class="celda" v-else></div>
                            </td>
                        </tr>
                    </table>
                </div>
            </q-card-section>
            <q-card-section>
                <h6 v-if="seleccionadas != 0"><span>Seleccionaste {{ seleccionadas }} entrada(s)<br>{{
                    store.obtener_pesos(sector.precio * seleccionadas) }}</span></h6>
                <h6 v-else><br><br></h6>
                <q-chip v-show="mensaje != ''">
                    <q-avatar :icon="errores ? 'close' : 'check'" :color="errores ? 'red' : 'green'"
                        text-color="white" />
                    <div class="ellipsis-2-lines">
                        {{ mensaje }}
                        <q-tooltip>{{ mensaje }}</q-tooltip>
                    </div>
                </q-chip>
            </q-card-section>

        </div>
        <q-card-actions align="left" class="text-teal">
            <q-btn dense v-if="!loading" @click="anadir_al_carrito()" :disabled="loading || seleccionadas == 0"
                color="green">
                Añadir al carrito
                <q-icon name="add_shopping_cart" />
            </q-btn>
            <q-btn dense v-else :disabled="loading" color="grey">Añadiendo, aguarde...
                <q-circular-progress indeterminate rounded class="q-ml-md" />
            </q-btn>
            <q-btn dense v-close-popup :disabled="loading" color="red" v-if="!ir_al_carrito">
                Cancelar
                <q-icon name="close" />
            </q-btn>
            <q-btn flat dense @click="router.push({ name: 'carrito' })" :disabled="loading" color="primary" v-else
               >
                Ir al carrito
                <q-icon name="shopping_cart_checkout" />
            </q-btn>
        </q-card-actions>
    </q-card>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useEPStore } from "@/store/store";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps(["sector"]);
// console.log(props)
const store = useEPStore();

const loading_ubicaciones = ref(true);
const loading = ref(false);
const error = ref(false);
const seleccionadas = ref(0);
const ir_al_carrito = ref(false)

const filas = ref([])
const ubicaciones = ref([])
const seleccion_actual = ref({})
const errores = ref(false);
const mensaje = ref("");
onMounted(() => {
    obtener_ubicaciones();
});

function seleccionar_ubicacion(ubicacion) {
    if (loading_ubicaciones.value) { return; }
    if (ubicacion["estado"] == "libre") {
        if (seleccion_actual.value[ubicacion["id"]] == null) {
            seleccion_actual.value[ubicacion["id"]] = ubicacion;
            seleccionadas.value++;
        } else {
            delete seleccion_actual.value[ubicacion["id"]]
            seleccionadas.value--;
        }
    }
    
    ir_al_carrito.value=false;
}

function eliminar_seleccion() {
    seleccion_actual.value = {}
    seleccionadas.value = 0;
}

function obtener_clases(ubicacion) {
    let claass = "";
    claass += ubicacion["estado"];
    if (seleccion_actual.value[ubicacion["id"]] != null) {
        claass += " seleccion_actual";
    }
    return claass;

}

function obtener_ubicaciones() {
    error.value = false;
    loading_ubicaciones.value = true;
    return axios
        .get(`${store.host}/webapp/mapa_ubicaciones?seccion_id=${props.sector.id}&${store.obtener_credenciales()}`)
        .then(({ data }) => {
            loading_ubicaciones.value = false;
            filas.value = data.ubicaciones
        })
        .catch((e) => {
          // console.log(e);
            error.value = true;
        });
}


function anadir_al_carrito() {
    loading.value = true;
    mensaje.value = "";
    errores.value = false;
    let ubics = []
    for (const tmp in seleccion_actual.value) {
        ubics.push({ "seccion": `${props.sector.id}`, "ubicacion": `${tmp}`, "cantidad": "1", "funcion_id": store.funcion.id })
    }

    axios
        .post(
            `${store.host}/webapp/agregar_entradas`,
            `token=${store.user.token}&user=${store.user.id}&verificar_ubicaciones=true&funcion=${store.funcion.id}&seccion_id=${props.sector.id}&ubicaciones=${JSON.stringify(ubics)}`
        )
        .then(({ data }) => {
            //{"Result":"error","TotalRecordCount":0,"Message":"No se pueden seleccionar esas ubicaciones, ya fueron ocupadas"}
            //{"Result":"ok","TotalRecordCount":0,"Record":{"venta_id":135462,"evento_id":0,"funcion_id":0,"venta_fecha":"2023-06-15","venta_hora":"20:03:43","usuario_id":255,"venta_estado":1,"venta_tipo":"boleteria","entrega_id":0,"transaccion_nps_id":0,"venta_envio_id":0,"venta_fpago":0,"cupon_id":0,"reserva_id":0,"venta_usuario_impresion":0,"venta_fecha_limite":"2023-06-15","venta_hora_limite":"20:18:43","tiempo_extendido":0,"venta_bloqueada":0},"Message":"135462"}

            if (data.Result == "ok") {
                if (seleccionadas.value == 1) {
                    mensaje.value = `¡${seleccionadas.value} entrada añadida exitosamente!`;
                } else {
                    mensaje.value = `¡${seleccionadas.value} entradas añadidas exitosamente!`;
                }
                store.obtener_carrito();
                obtener_ubicaciones();
                eliminar_seleccion();
                ir_al_carrito.value=true;
            } else {
                mensaje.value =
                    "Lo sentimos, no hay disponibilidad en el sector seleccionado";
                if (data.Message != null) {
                    mensaje.value =
                        `${data.Message}`;

                }
                errores.value = true;
            }
          // console.log(data);
            loading.value = false;
        }).catch((e) => {
            loading.value = false;

            mensaje.value =
                "Ocurrió un error, asegurate de estar conectado a internet";
            errores.value = true;
        });
}

</script>



<style>
.ocupada,
.seleccionada {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHg9IjgiIHk9IjYiIGZpbGw9IiMyNTM2NTUiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIGZpbGw9IiMzNzQ5NkMiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIHg9IjIyIiBmaWxsPSIjMzc0OTZDIiByeD0iMiIvPjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyMiIgZmlsbD0iIzRBNUQ4MSIgcng9IjIiLz48L2c+PC9zdmc+) 50% 50% no-repeat;
    opacity: .4
}

.libre {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHg9IjgiIHk9IjYiIGZpbGw9IiMyNTM2NTUiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIGZpbGw9IiMzNzQ5NkMiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIHg9IjIyIiBmaWxsPSIjMzc0OTZDIiByeD0iMiIvPjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyMiIgZmlsbD0iIzRBNUQ4MSIgcng9IjIiLz48L2c+PC9zdmc+) 50% 50% no-repeat;

}

.libre:not(.nodisponible) {
    cursor: pointer;

}


.nodisponible {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgc3R5bGU9IiI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHg9IjgiIHk9IjYiIGZpbGw9IiM4NTBCMTMiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIGZpbGw9IiNBODIwMjAiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIHg9IjIyIiBmaWxsPSIjQTgyMDIwIiByeD0iMiIvPjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyMiIgZmlsbD0iI0QxNEY1OCIgcng9IjIiLz48L2c+PC9zdmc+) 50% 50% no-repeat;
}

/* .libre:not(.nodisponible):not(.seleccion_actual):not(.muestra):hover {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHg9IjgiIHk9IjYiIGZpbGw9IiNCRDZBMDUiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIGZpbGw9IiNGRjkzMEYiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIHg9IjIyIiBmaWxsPSIjRkY5MzBGIiByeD0iMiIvPjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyMiIgZmlsbD0iI0ZGQTQzNCIgcng9IjIiLz48L2c+PC9zdmc+) 50% 50% no-repeat;
    filter: hue-rotate(60deg);

} */

.seleccion_actual {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHg9IjgiIHk9IjYiIGZpbGw9IiNCRDZBMDUiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIGZpbGw9IiNGRjkzMEYiIHJ4PSIyIi8+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iMjYiIHg9IjIyIiBmaWxsPSIjRkY5MzBGIiByeD0iMiIvPjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyMiIgZmlsbD0iI0ZGQTQzNCIgcng9IjIiLz48L2c+PC9zdmc+) 50% 50% no-repeat !important;
}

.celda {
    width: 30px;
    height: 30px;
}

h5,
h6 {
    margin: 0px;
}
</style>