<template>
    

    <div id="canvas1">
        <svg id="svg">
            <g  v-for="tela in telas" :key="tela.id">
                <e-rect v-if="tela.type=='rectx'" :tela="tela"></e-rect>
                <e-text v-if="tela.type=='textx'" :tela="tela"></e-text>
                <e-path v-if="tela.type=='pathx'" :tela="tela"></e-path> 
                <e-image v-if="tela.type=='image'"  :tela="tela" :url="props.url"></e-image>
    
               
            </g>

            
        </svg>
    </div>
</template>
  
<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useEPStore } from "@/store/store";
import axios from "axios";
import EImage from "./MapComponents/Image.vue";
import EPath from "./MapComponents/Path.vue";
import ERect from "./MapComponents/Rect.vue";
import EText from "./MapComponents/Text.vue";

const store = useEPStore();
const router = useRouter();
const props = defineProps(["sectores", "mapa","url"]);
const dialog = ref(false);
const sectorActual = ref(null);
const cantidad_entradas = ref(1);
const loading = ref(false);
const errores = ref(false);
const mensaje = ref("");
const telas = ref(JSON.parse(props["mapa"]))
console.log(telas.value)

function seleccionar_sector(sector) {
    sectorActual.value = sector;
    dialog.value = true;
}

// function cerrar_dialogo() {
//   dialog.value = false;
// }

function get_clase(sector) {
    let clase = "bg-orange-9";
    if (sector.cantidad == 0) {
        clase = "bg-grey-9";
    }
    return (
        "full-width col-12  q-ma-md   row  text-white text-center text-weight-bold " +
        clase
    );
}

function cambiar_cantidad(sumar) {
    if (!sumar) {
        if (cantidad_entradas.value <= 1) return;
        cantidad_entradas.value--;
    } else {
        cantidad_entradas.value++;
    }
}

function anadir_al_carrito() {
    loading.value = true;
    mensaje.value = "";
    errores.value = false;
    axios
        .post(
            `${store.host}/webapp/agregar_entradas`,
            `token=${store.user.token}&user=${store.user.id}&funcion=${store.funcion.id}&ubicaciones=[{"seccion":"${sectorActual.value.id}","ubicacion":"0","cantidad":"${cantidad_entradas.value}","funcion_id":${store.funcion.id}}]`
        )
        .then(({ data }) => {
            //{"Result":"error","TotalRecordCount":0,"Message":"No se pueden seleccionar esas ubicaciones, ya fueron ocupadas"}
            //{"Result":"ok","TotalRecordCount":0,"Record":{"venta_id":135462,"evento_id":0,"funcion_id":0,"venta_fecha":"2023-06-15","venta_hora":"20:03:43","usuario_id":255,"venta_estado":1,"venta_tipo":"boleteria","entrega_id":0,"transaccion_nps_id":0,"venta_envio_id":0,"venta_fpago":0,"cupon_id":0,"reserva_id":0,"venta_usuario_impresion":0,"venta_fecha_limite":"2023-06-15","venta_hora_limite":"20:18:43","tiempo_extendido":0,"venta_bloqueada":0},"Message":"135462"}

            if (data.Result == "ok") {
                if (cantidad_entradas.value == 1) {
                    mensaje.value = `¡${cantidad_entradas.value} entrada añadida exitosamente!`;
                } else {
                    mensaje.value = `¡${cantidad_entradas.value} entradas añadidas exitosamente!`;
                }
                store.obtener_carrito();
            } else {
                mensaje.value =
                    "Lo sentimos, no hay disponibilidad en el sector seleccionado";
                errores.value = true;
            }
          // console.log(data);
            loading.value = false;
        }).catch((e) => {
            loading.value = false;

            mensaje.value =
                "Ocurrió un error, asegurate de estar conectado a internet";
            errores.value = true;
        });
}

function reiniciar_cantidad() {
    cantidad_entradas.value = 1;
}
</script>

<style scoped>
#canvas1{
    max-width: 600px;
    max-height: 600px;
    min-width: 200px;
    min-height: 200px;
    border: 1px solid grey;
    border-radius: 6px;
    margin:auto;
    aspect-ratio: 1 / 1;
}

#svg{
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    left: -0.671875px;
}
</style>
  