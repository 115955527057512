<template>
    <!-- <q-page class="flex flex-center"> -->
    <div v-if="showKeyboard" class="keyboard">
        <div v-if="tipo_teclado == 'numerico'">
            <div class="keyboard-row  text-center" v-for="numericKey in numericKeyboard" :key="numericKey">
                <q-btn v-for="key in numericKey" :label="key" :key="key" flat @click="handleKeyPress(key)"
                    class="q-px-xl text-h5" />
            </div>
            <div class="keyboard-row  text-center">
                <q-btn icon="delete" flat @click="handleKeyPress('--')" class="q-px-xl" />
                <q-btn label="0" flat @click="handleKeyPress('0')" class="q-px-xl text-h5" />
                <q-btn icon="backspace" flat @click="deleteLastChar" class=" q-px-xl " />
            </div>
        </div>
        <div v-if="tipo_teclado == 'texto'">
            <div v-if="showKeyboard" class="keyboard ">
                <div v-for="row in qwertyKeyboard" :key="row">
                    <q-btn v-for="key in row"  :key="key" flat @click="handleKeyPress(key)"
                        class="q-py-none q-px-lg text-h4" ><q-icon v-if="(key=='')" name="backspace" ></q-icon><q-icon v-if="(key==' ')" name="space_bar" ></q-icon> {{ key }}</q-btn>
                </div>

            </div>
            <!-- <q-btn label="Cerrar" color="negative" @click="showKeyboard = false" class="q-mt-md" /> -->
        </div>
    </div>
    <!-- </q-page> -->
</template>

<script>
export default {
    props: ['tipo_teclado'],
    data() {
        return {
            inputValue: '',
            showKeyboard: true,
            numericKeyboard: [
                ['1', '2', '3'],
                ['4', '5', '6'],
                ['7', '8', '9']
            ],
            qwertyKeyboard: [
                ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
                ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
                ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
                ['Z', 'X', 'C', 'V', 'B', 'N', 'M',''],
                ['@','.', ' ',  '-', '_', '.COM'],
               
            ]
        };
    },
    methods: {
        handleKeyPress(key) {

            if (key === '--') this.inputValue = '' 
            if (key === '') {
                this.deleteLastChar();
                return;
            }
            this.inputValue += key;
            this.$emit("setTelefono", this.inputValue)
        },
        deleteLastChar() {
            this.inputValue = this.inputValue.slice(0, -1);
            this.$emit("setTelefono", this.inputValue)
        }
    }
};
</script>

<style>
.keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: #f5f5f5; */
    padding: 10px;
    border-radius: 5px;
}

.keyboard-row {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}
</style>