import CarritoView from "@/views/Carrito/CarritoView.vue";
import CheckOutView from "@/views/CheckOutView.vue";
import EventoView from "@/views/Evento/EventoView.vue";
import FuncionView from "@/views/Funcion/FuncionView.vue";
import { createRouter, createWebHashHistory  } from "vue-router";
import HomeView from "../views/Home/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import ErrorView from "@/views/ErrorView.vue";
import Ventas from "@/views/Carrito/Ventas.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: true },
    component: HomeView,
  },
  {
    path: "/evento/:evento_slug",
    name: "evento",
    meta: { requiresAuth: true },
    props: true,
    component: EventoView,
  },
  {
    path: "/evento/:evento_slug/funcion/:funcion_id",
    name: "funcion",
    meta: { requiresAuth: true },
    props: true,
    component: FuncionView,
  },
  {
    path: "/carrito",
    name: "carrito",
    meta: { requiresAuth: true },
    props: true,
    component: CarritoView,
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: { requiresAuth: true },

    props: true,
    component: CheckOutView,
  },
  {
    path: "/ventas",
    name: "ventas",
    meta: { requiresAuth: true },

    props: true,
    component: Ventas,
  },
  {
    path: "/login",
    name: "login",
    props: true,
    component: LoginView,
  },
  {
    path: "/error",
    name: "error",
    props: true,
    component: ErrorView,
  },
  // {
  //   path: "/evento",
  //   name: "evento",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory (process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  // console.log(from);
  // console.log(to);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log("ENTRO!")
    if (!loggedIn) {
      next("/login");
    } else {
      next();
    }
  } else {
    // console.log("no entro!")
    next();
  }

  //  next()
});

export default router;
